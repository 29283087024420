import React, {useState} from "react";
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {graphql} from 'gatsby';
import RevealerWrapper from '../../../../components/Revealer/RevealerWrapper';
import VideoIcons from '../../../../components/video-player/VideoIcons';
import ReactVideo360 from "../../../../components/video-player/ReactVideo360";
import posterStandardBrushed from '../../../../images/support/posters/360s/axis/poster_Axis_Standard_Keypad_Brushed.jpeg';
import posterStandardRfid from '../../../../images/support/posters/360s/axis/poster_Axis_Standard_RFID_Brushed.jpeg';
import posterVerticalKeypad from '../../../../images/support/posters/360s/axis/poster_Axis_Vertical_Keypad_Brushed.jpeg';
import posterVerticalRfid from '../../../../images/support/posters/360s/axis/poster_Axis_Vertical_RFID_Brushed.jpeg';
import {createMarkup} from '../../../../utils/utils';
import compareLeft from './images/NKAXSTWPKPBN_360ROT_FF.jpg';
import compareRight from './images/NKAXSTWPRFBN_360ROT_FF.jpg';
import {StaticImage} from "gatsby-plugin-image";


const Axis = (props) => {

  const {t} = useTranslation();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [bcVideoId, setBcVideoId] = useState('https://cdn.mediavalet.com/usva/digilock/2Avu5kIzZU-dtWFonUYvGQ/yQ3OciZgtUSDRohCzPZQGw/Original/Axis_Standard_Keypad_Brushed.mp4');
  const [currentPoster, setCurrentPoster] = useState(posterStandardBrushed);

  function videoIconClickHandler(index, vid, poster) {
    setCurrentVideoIndex(index);
    setBcVideoId(vid);
    setCurrentPoster(poster)
  }

  return (
      <Layout>
        <Seo
            title={t('products_electronic_locks_axis')}
            description={t('des_products_electronic_locks_axis')}
        />
        <div className={'products electronic-locks axis'}>
          <section className="details">
            <div className="container">
              <StaticImage
                  src="../../../../images/logos/logo-axis.png"
                  loading={'lazy'}
                  width={252}
                  height={58}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Axis logo"
              />
              <p dangerouslySetInnerHTML={{__html:t('axis_legacy_support_link')}} />
              <br/><br/>
              <h1><Trans>product_details</Trans></h1>
              <div className={'grid-two-col'}>

                <div className="grid-two-col-item">
                  <div className="revealer center-top">
                    <RevealerWrapper
                        compareLeft={compareRight}
                        compareRight={compareLeft}
                        width={180}
                        labelLeft={createMarkup(t('touch'))}
                        labelRight={createMarkup(t('standard'))}
                        clsName={'nextlock'}
                    />
                  </div>
                  <div className="product-copy">
                    <h2>
                      {t('interface')}
                    </h2>
                    <h3>
                      {t('front_unit')}
                    </h3>
                    <h3>
                      {t('standard')} {t('keypad')} : {t('rfid')}
                    </h3>
                    <p className={'pad-top'}>
                      Axis {t('interface_copy')}
                    </p>
                    <h3>
                      {t('power')} | 4 AA {t('batteries')}
                    </h3>
                    <p className={'pad-top'}>
                      {t('interface_power_copy')}*
                    </p>
                    <p className={'footnote'}>
                      <em>
                        *{t('based_on_average_usage')}
                      </em>
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div className="video-360">
                    <ReactVideo360
                        poster={currentPoster}
                        vURL={bcVideoId} />
                  </div>
                  <div className="product-copy">
                    <h2>
                      {t('body_style')}
                    </h2>
                    <h3>
                      {t('front_unit')}
                    </h3>
                    <h3>
                      {t('finish')} | {t('brushed_nickel')} : {t('black')}
                    </h3>
                    <p>
                      {t('body_finish_copy')}
                    </p>
                    <h3>
                      {t('body')} | {t('standard')} : {t('vertical')} : {t('horizontal')}
                    </h3>
                    <p>
                      {t('body_copy')}
                    </p>
                    <VideoIcons
                        currentVideoIndex={currentVideoIndex}
                        videoIconClickHandler={videoIconClickHandler}
                        toggleClassNames={
                          [
                            'icon-standard-keypad',
                            'icon-standard-touch',
                            'icon-vertical-keypad',
                            'icon-vertical-touch'
                          ]
                        }
                        bcVideoIds={
                          [
                            'https://cdn.mediavalet.com/usva/digilock/2Avu5kIzZU-dtWFonUYvGQ/yQ3OciZgtUSDRohCzPZQGw/Original/Axis_Standard_Keypad_Brushed.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/65eK1OEVuU-AZEYmm4OJXg/MlB1X7edn0q1p1bN4pj6NQ/Original/Axis_Standard_RFID_Brushed.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/ad3rU6s790qhiVcK5dqoaw/K2QUhVU30UCRAPM_6y6nOA/Original/Axis_Vertical_Keypad_Brushed.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/sU7h_iwxuEmHQHpEitzrjg/wyxRCSzIoUy2lcyCVjRCtg/Original/Axis_Vertical_RFID_Brushed.mp4'
                          ]
                        }
                        posters={
                          [
                              posterStandardBrushed,
                              posterStandardRfid,
                              posterVerticalKeypad,
                              posterVerticalRfid
                          ]
                        }
                    >&nbsp;</VideoIcons>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="rear-units">
            <div className="container">
              <h1>{t('locking_options')}</h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/rear-units/5g-bolt.png'}
                        loading={'lazy'}
                        width={364}
                        height={247}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Bolt rear unit"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('deadlatch')}
                    </h2>
                    <h3>
                      {t('rear_unit')}
                    </h3>
                    <p>
                      {t('deadlatch_rear_unit_copy')}
                    </p>
                    <h3>
                      {t('assigned_use')}
                    </h3>
                    <p>
                      {t('deadlatch_assigned_use_copy')}
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/rear-units/5g-latch.png'}
                        loading={'lazy'}
                        width={364}
                        height={247}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Latch rear unit"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('deadbolt')}
                    </h2>
                    <h3>
                      {t('rear_unit')}
                    </h3>
                    <p>
                      {t('deadbolt_rear_unit_copy')}
                    </p>
                    <h3>
                      {t('shared_use')}
                    </h3>
                    <p>
                      {t('deadbolt_shared_use_copy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="management">
            <div className="container">
              <h1>{t('management')}</h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/management/app-manage-next.png'}
                        loading={'lazy'}
                        width={214}
                        height={216}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="App management"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('lock_management')}
                    </h2>
                    <p>
                      {'Axis '+t('lock_management_copy')}<br /><Link to='/contact/'>{t('contact_digilock_sales')}</Link>
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/management/app-manage-keys-all.png'}
                        loading={'lazy'}
                        width={169}
                        height={192}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Management keys"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('key_management')}
                    </h2>
                    <p>
                      {t('key_management_copy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  );
};

export default Axis;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
